import axios from '@/utils/axios.ts';
/**
 * 检查可否发送
 */
export async function exchageContent(extra) {
  return axios({
    method: 'GET',
    url: `/mobi-investor/sixin/v3/${extra}/exchageContent`,
  });
}
/**
 * 检查剩余可用次数
 */
export async function contactNum(extra) {
  return axios({
    method: 'GET',
    url: '/mobi-investor/sixin/v3/contactNum',
    params: {
      uid: extra,
    },
  });
}

/**
 * 创业者与投资人聊天获取剩余聊天次数
 * @param {*} params
 */
export async function entrepreneurContactNum(params) {
  return axios({
    method: 'get',
    url: '/mobi-investor/sixin/v3/entrepreneurContactNum',
    params: {
      uid: params,
    },
  });
}

/**
 * 选取项目列表
 */
export async function privilegeList(params) {
  return axios({
    method: 'GET',
    url: '/mobi-investor/v3/company/user/privilege',
    params: {
      uid: params,
    },
  });
}

/**
 * 产生聊天记录， 发送项目信息卡片
 */
export function sendCompanyInfo(extra) {
  return axios({
    method: 'POST',
    url: `/mobi-investor/sixin/v3/${extra.ccid}/sendCompanyInfo`,
    data: extra,
  });
}
/**
 * 发送BP
 */
export function sendBp(extra) {
  return axios({
    method: 'POST',
    url: `/mobi-investor/sixin/v3/${extra.uid}/sendBp`,
    data: extra,
  });
}

/**
 * 判断可用聊天次数
 * @param {*} params
 */

export async function fetchContactNum(params) {
  return axios({
    method: 'get',
    url: '/mobi-investor/sixin/v3/contactNum',
    params: {
      uid: params.uid,
    },
  });
}

/**
 * 创建消息记录
 * @param {*} params
 */
export async function createContactRecord(params) {
  return axios({
    method: 'get',
    url: `/mobi-investor/sixin/dialog/${params.uid}`,
    params: {
      stats_refer: 'jingdataT',
      lastId: 0,
      pageSize: 0,
      flip_type: 1,
    },
  });
}
