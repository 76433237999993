<template>
  <div
    v-loading="loading"
    class="option-user-wrapper"
  >
    <div class="head">
      <div class="avatar-container">
        <logo-panel
          v-if="!investorCode.includes(cardInfo.investorType)"
          :info="cardInfo"
          class="avatar"
        />
        <logo-panel
          v-else
          :info="cardInfo"
          class="avatar avatar-clickable"
          @click.native="goToLink('investor')"
        />
      </div>

      <div class="basic-info">
        <div class="name">
          <span
            v-if="investorCode.includes(cardInfo.investorType)"
            class="name-clickable"
            @click="goToLink('investor')"
          >
            {{ cardInfo.name || '新用户' }}
          </span>
          <span v-else>
            {{ cardInfo.name || '新用户' }}
          </span>
        </div>
        <div class="identity">
          <span v-if="cardInfo.org && cardInfo.position">
            <span
              class="org"
              :class="[!(cardInfo.investorType === 100 && cardInfo.enterpriser !== 2)
                ? 'identity-clickable': '']"
              @click="goToLink('org')"
            >{{ cardInfo.org }}</span> - {{ cardInfo.position }}
          </span>
          <span v-else-if="cardInfo.org && !cardInfo.position">
            <span
              class="org"
              :class="[!(cardInfo.investorType === 100 && cardInfo.enterpriser !== 2)
                ? 'identity-clickable': '']"
              @click="goToLink('org')"
            >{{ cardInfo.org }}</span>
          </span>
          <span v-else-if="!cardInfo.org && cardInfo.position">
            {{ cardInfo.position }}
          </span>
          <span v-else> - </span>
        </div>
        <div class="social-num">
          <b>{{ cardInfo.fans || '0' }}</b>粉丝
          <b class="follow"> {{ cardInfo.follows || '0' }}</b>关注
        </div>
      </div>
    </div>
    <p
      v-if="cardInfo.intro"
      class="info"
    >
      {{ cardInfo.intro }}
    </p>

    <div class="operate-button">
      <template v-if="profile.uid !== user.uid">
        <el-button
          v-sensor:click="{
            $element_id: 'live_follow',
            consumer_id: profile.uid,
            room_id: roomInfo.id,
          }"
          size="mini"
          class="follow-btn"
          :class="{isFollowBtn: cardInfo.isFollow }"
          @click="handleFollow"
        >
          {{ cardInfo.isFollow ? '已关注' : '关注' }}
        </el-button>
      </template>
      <!-- 仅投资人可被 创业者与未认证用户、创投、创服点击-->
      <template
        v-if="profile.uid !== user.uid
          && investorCode.includes(cardInfo.investorType)
          && (isEnterpriser || !(isInvestor || isOrg))"
      >
        <el-button
          size="mini"
          class="contact-btn"
          plain
          @click="handleSendBp"
        >
          投递BP
        </el-button>
      </template>
      <!-- 仅投资人可被创业者投资人与未认证、创服双用户点击(非投资机构) / 创业者可被未认证、投资人、创投双点击 -->
      <template v-if="profile.uid !== user.uid && hasContact">
        <el-button
          size="mini"
          class="contact-btn"
          plain
          @click="handleContact"
        >
          立即沟通
        </el-button>
      </template>
      <template v-if="(profile.uid === user.uid && isGuest && !isLiveStream)">
        <el-button
          size="mini"
          @click="beforeHandleCommand('setOneselfAudience')"
        >
          将自己设为观众
        </el-button>
      </template>
      <template v-if="fromView==='live' && isLiveAdmin">
        <template v-if="(isAudience && !isLiveStream)">
          <el-button
            size="mini"
            @click="beforeHandleCommand('inviteBeGest')"
          >
            邀请为LIVE嘉宾
          </el-button>
        </template>
        <el-button
          v-if="isAudience"
          size="mini"
          @click="beforeHandleCommand('kickOutRoom')"
        >
          移出房间
        </el-button>
      </template>
    </div>
    <authentication-dialog
      :visible="visible"
      :auth-msg="authMsg"
      :type="authType"
      @update:visible="handleVisible"
    />
    <deliverye-dialog
      :visible.sync="deliveryeVisible"
      :list-info="deliveryeList"
      @setDeliveryeList="setDeliveryeList"
    />
    <select-project-dialog
      :visible.sync="selectProjectDialog.visible"
      :list="selectProjectDialog.projectList"
      @confirm="handleSelectProjectConfirm"
    />
    <chat-limit-dialog
      :visible.sync="chatDialog.visible"
      :pay="chatDialog.pay"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import {
  exchageContent,
  fetchContactNum,
  privilegeList,
  createContactRecord,
  sendCompanyInfo,
  entrepreneurContactNum,
} from '@/services/contact';
import LogoPanel from '@/views/components/LogoPanel';
import { setFollow } from '@/services/room';
import { getCloudToken } from '@/services/home';
import { cloudRequestPath, environmentHref } from '@/utils/cloudRequest';
import { userIsInvestor, userIsEnterpriser, userIsOrg } from '@/utils/user.js';
import deliverye from './mixin/deliverye';

export default {
  name: 'OptionUser',
  components: {
    LogoPanel,
    AuthenticationDialog: () => import('@/views/components/AuthenticationDialog'),
    SelectProjectDialog: () => import('@/views/components/Contact/SelectProjectDialog'),
    DeliveryeDialog: () => import('@/views/components/Contact/DeliveryeDialog'),
    ChatLimitDialog: () => import('@/views/components/Contact/ChatLimitDialog'),
  },
  mixins: [
    deliverye,
  ],
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
    cardInfo: {
      type: Object,
      default: () => ({}),
    },
    fromView: {
      type: String,
      default: 'live',
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      visible: false,
      authMsg: '',
      authType: '',
      // deliveryeVisible: false,
      selectProjectDialog: {
        visible: false,
        projectList: [],
      },
      orgCode: [200, 300, 400],
      investorCode: [10, 20, 50],
      enterpriserCode: 2,
    };
  },
  computed: {
    ...mapState('room', ['roomInfo']),
    ...mapGetters('room', ['isLiveStream']),
    isLiveAdmin() {
      return this.roomInfo.currentUser?.liveUserType === 0;
    },
    ...mapState('user', [
      'isLogin', 'profile',
    ]),
    ...mapGetters('user', [
      'isEnterpriser',
      'isInvestor',
      'isOrg',
    ]),
    isAdmin() {
      return this.user.liveUserType === 0;
    },
    isGuest() {
      return this.user.liveUserType === 1;
    },
    isAudience() {
      return this.user.liveUserType === 2;
    },
    needWhiteBoard() { // 是否需要启用白板
      return this.roomInfo.needWhiteBoard;
    },
    hasContact() {
      // 投资人可被创业者投资人与未认证、创服双用户点击(非投资机构)
      if (this.investorCode.includes(this.cardInfo.investorType)) {
        // eslint-disable-next-line max-len
        return this.isInvestor || this.isEnterpriser || !this.isOrg;
      }
      // 创业者可被未认证、投资人、创投双点击
      if (this.cardInfo.enterpriser === 2) {
        return this.isInvestor || (!this.isEnterpriser && !this.isOrg);
      }
      return false;
    },
  },
  methods: {
    ...mapActions('login', [
      'showLoginDialog',
    ]),
    async goToLink(type) {
      if (!this.isLogin) {
        this.showLoginDialog();
        return;
      }
      // 非认证用户
      if (this.cardInfo.investorType === 100 && this.cardInfo.enterpriser !== 2) {
        return;
      }
      const token = await getCloudToken();
      const { uid } = this.user;
      let fullPath = `${environmentHref}/#/`;
      const time = new Date().getTime();
      if (type === 'investor') {
        fullPath = `${fullPath}insight/InsightInvestor/${uid}?_t_=${time}`;
      } else if (type === 'org') {
        const { orgId, orgType } = this.cardInfo;
        if (orgType === 0) {
          fullPath = `${fullPath}insight/InsightOrg/${orgId}?_t_=${time}`;
        } else {
          fullPath = `${fullPath}insight/InsightProject/${orgId}?_t_=${time}`;
        }
        this.$emit('click-card-command', { command: 'project' });
      }
      window.open(`${cloudRequestPath}&token=${token}&redirectUrl=${fullPath}`, 'cloud');
    },
    handleVisible() {
      this.visible = false;
    },
    async checkBpTime() {
      const { uid } = this.cardInfo;
      const result = await exchageContent(uid);
      if (result && !result.bp && result.hasBp) {
        this.deliveryeVisible = true;
      }
    },
    beforeHandleCommand(command) {
      this.$emit('click-card-command', { item: this.user, command });
    },
    async handleFollow() {
      if (!this.isLogin) {
        this.showLoginDialog();
        return;
      }
      const { isFollow } = this.cardInfo;
      const { uid } = this.user;
      const msg = isFollow ? '取消关注成功' : '关注成功';
      const params = {
        followedUid: uid,
        type: isFollow,
        actionStat: this.fromView === 'live' ? 1 : 0,
      };
      if (this.fromView === 'live') {
        params.roomId = this.$route.params.id;
      }
      try {
        await setFollow(params);
        this.$emit('updateCardInfo', uid);
        if (this.fromView === 'live' && isFollow === 0) {
          this.$emit('click-card-command', { item: this.user, command: 'follow' });
        }
        this.$message.success(msg);
      } catch (error) {
        // console.log(error)
      }
    },
    async handleSendBp() {
      if (!this.isLogin) {
        this.showLoginDialog();
        return;
      }
      if (!this.isEnterpriser && !this.isInvestor && !this.isOrg) {
        this.authMsg = '投递BP为鲸准平台认证创业者用户特权，请先完成创业者身份认证。';
        this.authType = 'EntrepreneurAuth';
        this.visible = true;
        return;
      }
      const { uid } = this.cardInfo;
      if (this.isEnterpriser) {
        try {
          // await this.checkBpTime();
          await this.handleDeliverye({ id: uid });
        } catch (e) {
          //
        }
      } else {
        this.visible = true;
      }
    },
    async handleContact() {
      if (!this.isLogin) {
        this.showLoginDialog();
        return;
      }
      // 必须进行身份验证
      if (!this.isEnterpriser && !this.isInvestor) {
        const { investorType } = this.cardInfo;
        const isInvestor = this.investorCode.includes(investorType);
        this.authMsg = isInvestor
          ? '与投资人私信聊天为鲸准平台认证用户特权，请先完成身份认证。'
          : '与创业者私信聊天为鲸准平台认证投资人用户特权，请先完成身份认证。';
        this.authType = isInvestor ? 'EntrepreneurAuth' : 'InvestorAuth';
        this.visible = true;
        return;
      }
      // 判断对方身份 - 投资人/服务机构
      const userRoles = this.getUserRoles();
      if (userRoles === '投资人' || userRoles === '服务机构') {
        // 投资人/服务机构 -> 创业者
        if (this.isEnterpriser) {
          this.InvestorToEnterpriserConnect({ id: this.cardInfo.uid });
          return;
        }
        // 投资人/服务机构 ->  投资人
        if (this.isInvestor || this.isOrg) {
          this.InvestorToInvestorConnect();
        }
        return;
      }
      // 判断对方身份 - 创业者
      if (userRoles === '项目方') {
        // 创业者 -> 投资人
        if (this.isInvestor || this.isOrg) {
          this.EnterpriserToInvestorConnect();
        }
      }
    },
    async handleSelectProjectConfirm(project = {}) {
      const { ccid = null } = project;
      const { uid } = this.cardInfo;
      try {
        await sendCompanyInfo({
          chatIdentity: this.chatIdentity,
          ccid,
          uid,
        });
        await this.handleOpenChat(uid);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    },
    async handleOpenChat(uid) {
      // 返回的是字符串数据，转下
      const contacterId = Number.parseInt(uid, 10);
      const token = await getCloudToken();
      const fullPath = `${environmentHref}/#/home?chatId=${contacterId}&time${new Date().getTime()}`;
      if (token) {
        const roles = this.getUserRoles();
        window.open(`${cloudRequestPath}&token=${token}&redirectUrl=${fullPath}`, 'cloud');
        if (roles) {
          this.$emit('click-card-command', { command: 'chat', item: roles });
        }
      }
    },
    getUserRoles() {
      if (userIsInvestor(this.cardInfo?.investorType)) {
        return '投资人';
      }
      if (userIsOrg(this.cardInfo?.investorType)) {
        return '服务机构';
      }
      if (userIsEnterpriser(this.cardInfo?.enterpriser)) {
        return '项目方';
      }

      return false;
    },
    // 对方投资人 && 当前用户是创业者身份时，立即沟通走的逻辑
    async InvestorToEnterpriserConnect() {
      const { uid } = this.profile;
      const cardId = this.cardInfo.uid;
      const { buyOrChatted, popupStatus, popupIdentity } = await entrepreneurContactNum(cardId);
      this.chatIdentity = 'entrepreneur';
      if (popupStatus) {
        this.chatDialog = {
          visible: true,
          pay: Boolean(popupIdentity || buyOrChatted),
        };
      } else {
        this.handleSelectProject(uid);
      }
    },
    // 对方投资人 && 当前用户是投资人/服务机构 身份时，立即沟通走这里
    async InvestorToInvestorConnect() {
      const { uid } = this.cardInfo;
      const { buyOrChatted, num } = await fetchContactNum({
        uid,
      });
      const userRoles = this.getUserRoles();
      if (!(num > 0 || buyOrChatted)) {
        if (userRoles === '服务机构') {
          this.$message({
            dangerouslyUseHTMLString: true,
            message: '请前往<a style="color:#3e74ca" target="_blank" href="https://guide.lkme.cc/pc_landingpage.jsp?app_id=11235&app_name=%E9%B2%B8%E5%87%86&deeplink_id=6582338800582658&qrcode_url=https%3A%2F%2Fguide.lkme.cc%2Fml.jsp%3Fdurl%3Dhttps%253A%252F%252Flkme.cc%252F5vC%252FO19tTlH1U&is_imessage=false&logo=app%2Flogo%2F11145_1583723709855.png">鲸准APP</a>进行购买，购买完成后您将获得该项目联系方式的永久查看权限',
            type: 'warning',
            duration: 5000,
          });
        } else {
          this.$message.warning('抱歉，剩余聊天次数为0，您无法发消息给对方');
        }

        return;
      }

      try {
        await createContactRecord({
          uid,
        });
        await this.handleOpenChat(uid);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    },
    // 对方是创业者 && 当前用户是投资人/服务机构 身份时，立即沟通走这里
    async EnterpriserToInvestorConnect() {
      const { uid } = this.cardInfo;
      const { buyOrChatted, num } = await fetchContactNum({
        uid,
      });

      const userRoles = this.getUserRoles();
      if (!(num > 0 || buyOrChatted)) {
        if (userRoles === '服务机构') {
          this.$message({
            dangerouslyUseHTMLString: true,
            message: '请前往<a style="color:#3e74ca" target="_blank" href="https://guide.lkme.cc/pc_landingpage.jsp?app_id=11235&app_name=%E9%B2%B8%E5%87%86&deeplink_id=6582338800582658&qrcode_url=https%3A%2F%2Fguide.lkme.cc%2Fml.jsp%3Fdurl%3Dhttps%253A%252F%252Flkme.cc%252F5vC%252FO19tTlH1U&is_imessage=false&logo=app%2Flogo%2F11145_1583723709855.png">鲸准APP</a>进行购买，购买完成后您将获得该项目联系方式的永久查看权限',
            type: 'warning',
            duration: 5000,
          });
        } else {
          this.$message.warning('抱歉，剩余聊天次数为0，您无法发消息给对方');
        }
        return;
      }
      this.chatIdentity = 'investor';
      this.handleSelectProject(uid);
    },
    // 选择项目，打开私信聊天页
    async handleSelectProject(uid) {
      const id = uid;
      const result = await privilegeList(id);
      // 没有项目，就直接打开聊天页面
      if (result && !result.length) {
        this.handleOpenChat(this.user.uid);
        return;
      }
      if (result?.length > 1) {
        this.selectProjectDialog.visible = true;
        this.selectProjectDialog.projectList = result;
      } else {
        await this.handleSelectProjectConfirm(result[0]);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import './OptionUser.scss';
</style>
