import {
  privilegeList,
  sendCompanyInfo,
  sendBp,
  entrepreneurContactNum,
}
  from '@/services/contact.js';

export default {
  data() {
    return {
      currentDeliverye: {}, // 当前选中的投资项
      selectProject: {}, // 选中项目项
      deliveryeVisible: false, // 投递弹窗
      deliveryeList: [], // 投递列表
      chatDialog: {
        visible: false,
        pay: false,
      },
      chatIdentity: 'entrepreneur', // 当前用户角色
    };
  },
  computed: {},
  methods: {
    // 创业者与投资人聊天获取剩余聊天次数
    async getEntrepreneurContactNum(id) {
      try {
        const result = await entrepreneurContactNum(id);
        const { popupStatus, popupIdentity, buyOrChatted } = result;
        if (popupStatus) {
          this.chatDialog = {
            visible: true,
            pay: Boolean(popupIdentity || buyOrChatted),
          };
        } else {
          return true;
        }
      } catch (error) {
        // console.log(error)
      }
    },

    async handleDeliverye(item) {
      this.currentDeliverye = item;
      this.chatIdentity = 'entrepreneur';
      // 获取创业者与投资人聊天获取剩余聊天次数
      const noLimit = await this.getEntrepreneurContactNum(item.id);
      if (noLimit) {
        this.getDeliveryeList();
      }
    },

    /**
     * 检查剩余可用次数
     */
    // async getContactNum() {
    //   const result = await contactNum(this.profile.id);
    //   if (result.num > 0 || result.buyOrChatted) {
    //     this.getDeliveryeList();
    //   } else {
    //     this.$message.error('您今天的免费次数已用完。');
    //   }
    // },
    /**
     * 获取项目列表，如果列表1个，直接投递，多个显示列表弹窗
     */
    async getDeliveryeList() {
      const result = await privilegeList();
      if (result.length === 1) {
        this.selectProject = result[0];
        this.sendCompanyInfo();
      } else {
        this.deliveryeList = result;
        this.handleDeliveryeVisible(true);
      }
    },
    // 产生聊天记录，发送bp
    async sendCompanyInfo() {
      const sendBpParams = {
        uid: this.currentDeliverye.id,
        ccid: this.selectProject.ccid,
      };
      const companyParams = {
        uid: this.currentDeliverye.id,
        ccid: this.selectProject.ccid,
        chatIdentity: this.chatIdentity,
      };
      Promise.all([await sendCompanyInfo(companyParams), await sendBp(sendBpParams)]).then(() => {
        // 关闭项目弹窗
        this.handleDeliveryeVisible(false);
        this.$message.success('已将BP发送至对方邮箱');
        this.$emit('click-card-command', { command: 'bp' });
      });
    },
    setDeliveryeList(value) {
      this.selectProject = value;
      this.sendCompanyInfo();
    },
    /**
     * 项目列表弹窗
     */
    handleDeliveryeVisible(value) {
      this.deliveryeVisible = value;
      // 关闭弹窗list重制
      if (!value) {
        this.deliveryeList = [];
      }
    },

  },
};
