var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"option-user-wrapper"},[_c('div',{staticClass:"head"},[_c('div',{staticClass:"avatar-container"},[(!_vm.investorCode.includes(_vm.cardInfo.investorType))?_c('logo-panel',{staticClass:"avatar",attrs:{"info":_vm.cardInfo}}):_c('logo-panel',{staticClass:"avatar avatar-clickable",attrs:{"info":_vm.cardInfo},nativeOn:{"click":function($event){return _vm.goToLink('investor')}}})],1),_c('div',{staticClass:"basic-info"},[_c('div',{staticClass:"name"},[(_vm.investorCode.includes(_vm.cardInfo.investorType))?_c('span',{staticClass:"name-clickable",on:{"click":function($event){return _vm.goToLink('investor')}}},[_vm._v(" "+_vm._s(_vm.cardInfo.name || '新用户')+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.cardInfo.name || '新用户')+" ")])]),_c('div',{staticClass:"identity"},[(_vm.cardInfo.org && _vm.cardInfo.position)?_c('span',[_c('span',{staticClass:"org",class:[!(_vm.cardInfo.investorType === 100 && _vm.cardInfo.enterpriser !== 2)
              ? 'identity-clickable': ''],on:{"click":function($event){return _vm.goToLink('org')}}},[_vm._v(_vm._s(_vm.cardInfo.org))]),_vm._v(" - "+_vm._s(_vm.cardInfo.position)+" ")]):(_vm.cardInfo.org && !_vm.cardInfo.position)?_c('span',[_c('span',{staticClass:"org",class:[!(_vm.cardInfo.investorType === 100 && _vm.cardInfo.enterpriser !== 2)
              ? 'identity-clickable': ''],on:{"click":function($event){return _vm.goToLink('org')}}},[_vm._v(_vm._s(_vm.cardInfo.org))])]):(!_vm.cardInfo.org && _vm.cardInfo.position)?_c('span',[_vm._v(" "+_vm._s(_vm.cardInfo.position)+" ")]):_c('span',[_vm._v(" - ")])]),_c('div',{staticClass:"social-num"},[_c('b',[_vm._v(_vm._s(_vm.cardInfo.fans || '0'))]),_vm._v("粉丝 "),_c('b',{staticClass:"follow"},[_vm._v(" "+_vm._s(_vm.cardInfo.follows || '0'))]),_vm._v("关注 ")])])]),(_vm.cardInfo.intro)?_c('p',{staticClass:"info"},[_vm._v(" "+_vm._s(_vm.cardInfo.intro)+" ")]):_vm._e(),_c('div',{staticClass:"operate-button"},[(_vm.profile.uid !== _vm.user.uid)?[_c('el-button',{directives:[{name:"sensor",rawName:"v-sensor:click",value:({
          $element_id: 'live_follow',
          consumer_id: _vm.profile.uid,
          room_id: _vm.roomInfo.id,
        }),expression:"{\n          $element_id: 'live_follow',\n          consumer_id: profile.uid,\n          room_id: roomInfo.id,\n        }",arg:"click"}],staticClass:"follow-btn",class:{isFollowBtn: _vm.cardInfo.isFollow },attrs:{"size":"mini"},on:{"click":_vm.handleFollow}},[_vm._v(" "+_vm._s(_vm.cardInfo.isFollow ? '已关注' : '关注')+" ")])]:_vm._e(),(_vm.profile.uid !== _vm.user.uid
        && _vm.investorCode.includes(_vm.cardInfo.investorType)
        && (_vm.isEnterpriser || !(_vm.isInvestor || _vm.isOrg)))?[_c('el-button',{staticClass:"contact-btn",attrs:{"size":"mini","plain":""},on:{"click":_vm.handleSendBp}},[_vm._v(" 投递BP ")])]:_vm._e(),(_vm.profile.uid !== _vm.user.uid && _vm.hasContact)?[_c('el-button',{staticClass:"contact-btn",attrs:{"size":"mini","plain":""},on:{"click":_vm.handleContact}},[_vm._v(" 立即沟通 ")])]:_vm._e(),((_vm.profile.uid === _vm.user.uid && _vm.isGuest && !_vm.isLiveStream))?[_c('el-button',{attrs:{"size":"mini"},on:{"click":function($event){return _vm.beforeHandleCommand('setOneselfAudience')}}},[_vm._v(" 将自己设为观众 ")])]:_vm._e(),(_vm.fromView==='live' && _vm.isLiveAdmin)?[((_vm.isAudience && !_vm.isLiveStream))?[_c('el-button',{attrs:{"size":"mini"},on:{"click":function($event){return _vm.beforeHandleCommand('inviteBeGest')}}},[_vm._v(" 邀请为LIVE嘉宾 ")])]:_vm._e(),(_vm.isAudience)?_c('el-button',{attrs:{"size":"mini"},on:{"click":function($event){return _vm.beforeHandleCommand('kickOutRoom')}}},[_vm._v(" 移出房间 ")]):_vm._e()]:_vm._e()],2),_c('authentication-dialog',{attrs:{"visible":_vm.visible,"auth-msg":_vm.authMsg,"type":_vm.authType},on:{"update:visible":_vm.handleVisible}}),_c('deliverye-dialog',{attrs:{"visible":_vm.deliveryeVisible,"list-info":_vm.deliveryeList},on:{"update:visible":function($event){_vm.deliveryeVisible=$event},"setDeliveryeList":_vm.setDeliveryeList}}),_c('select-project-dialog',{attrs:{"visible":_vm.selectProjectDialog.visible,"list":_vm.selectProjectDialog.projectList},on:{"update:visible":function($event){return _vm.$set(_vm.selectProjectDialog, "visible", $event)},"confirm":_vm.handleSelectProjectConfirm}}),_c('chat-limit-dialog',{attrs:{"visible":_vm.chatDialog.visible,"pay":_vm.chatDialog.pay},on:{"update:visible":function($event){return _vm.$set(_vm.chatDialog, "visible", $event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }