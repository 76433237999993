<template>
  <div class="guest-group">
    <template v-for="(item, index) in list">
      <el-popover
        :key="index"
        width="270"
        placement="right-start"
        trigger="manual"
        class="guest-popover"
        :visible-arrow="false"
        popper-class="guest-popper"
        :value="!!item.showLastPop"
      >
        <option-user
          :user="item"
          :loading="loading"
          :card-info="cardInfo"
          :from-view="fromView"
          @updateCardInfo="handleUpdateCardInfo"
          @click-card-command="handleCommand"
        />
        <div
          slot="reference"
          class="guest"
          @click="handlerShowPopover(item, index)"
        >
          <logo-panel
            :info="item"
            class="logo-panel-avatar"
          />
          <div>
            <div class="top-info">
              <div
                :class="['guest-name',{
                  'guest-hover-none': !item.uid
                }]"
              >
                {{ item.name || '新用户' }}
              </div>
              <template>
                <span
                  v-if="item.liveUserType === 0"
                  class="guest-role admin"
                >管理员</span>
                <span
                  v-if="(item.roomAction === 1 && item.liveUserType !== 0 && !isLiveStream)"
                  class="guest-role ppt"
                >PPT</span>
              </template>
            </div>

            <div class="guest-identity">
              <span v-if="item.entityName && item.position">
                {{ item.entityName }} | {{ item.position }}
              </span>
              <span v-else-if="item.entityName && !item.position">
                {{ item.entityName }}
              </span>
              <span v-else-if="!item.entityName && item.position">
                {{ item.position }}
              </span>
              <span v-else> - </span>
            </div>
          </div>
        </div>
      </el-popover>
    </template>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import OptionUser from '@/views/components/OptionUser';
import LogoPanel from '@/views/components/LogoPanel';
import { getUserCardInfo } from '@/services/user';

export default {
  name: 'LiveGuest',
  components: {
    OptionUser,
    LogoPanel,
  },
  props: {
    guest: {
      type: Array,
      default: () => [],
    },
    fromView: {
      type: String,
      default: 'live',
    },
    roomId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      cardInfo: {},
      loading: false,
      upDate: false,
      currPop: null,
      list: [],
    };
  },
  computed: {
    ...mapState('user', [
      'profile',
    ]),
    ...mapState('room', [
      'popUserInfo',
    ]),
    ...mapGetters('room', ['isLiveStream']),
  },
  watch: {
    guest: {
      handler(val, old) {
        if (old) {
          const valN = [];
          const oldN = [];
          val.forEach((item) => {
            valN.push(item.uid, item.liveType);
          });
          old.forEach((itm) => {
            valN.push(itm.uid, itm.liveType);
          });
          if (JSON.stringify(valN) === JSON.stringify(oldN)) {
            return;
          }
          const list = this.guest.concat([]);
          if (this.popUserInfo.name === 'LiveGuest' && this.fromView === 'live') {
            const index = list.findIndex((itm) => {
              return itm.uid === this.popUserInfo.uid;
            });
            if (index !== -1) {
              list[index].showLastPop = true;
              this.currPop = index;
            } else {
              this.currPop = null;
            }
          }
          this.list = list.concat([]);
        } else {
          this.list = this.guest.concat([]).map((item) => {
            return { ...item, showLastPop: false };
          });
          this.currPop = null;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.setLiveUserPopState({ name: '', uid: '' });
    document.addEventListener('click', this.handleUserPopVisible);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleUserPopVisible);
    if (this.popUserInfo.name === 'LiveGuest' && this.fromView === 'live') {
      this.setLiveUserPopState({ name: '', uid: '' });
    }
  },
  methods: {
    ...mapActions('room', ['setLiveUserPopState']),
    handleUserPopVisible() {
      if (this.currPop !== null && this.list.length && this.currPop < this.list.length) {
        this.list[this.currPop].showLastPop = false;
        this.setLiveUserPopState({ name: '', uid: '' });
        this.$forceUpdate();
      }
    },
    handlerShowPopover(user, index) {
      const { uid } = user;
      if (!uid) return;
      if (this.list[index].showLastPop) {
        this.list[index].showLastPop = false;
        this.setLiveUserPopState({ name: '', uid: '' });
        return;
      }
      if (this.currPop !== null && this.list.length && this.currPop < this.list.length) {
        this.list[this.currPop].showLastPop = false;
        this.setLiveUserPopState({ name: '', uid: '' });
      }
      this.currPop = index;

      this.getCardInfo(uid);
      if (window.sensors) {
        sensors.track('$WebClick', {
          $element_id: 'live_to_personalpage',
          consumer_id: this.profile.uid,
          room_id: this.roomId,
        });
      }
    },
    async handleUpdateCardInfo(id) {
      this.upDate = true;
      await this.getCardInfo(id);
      this.upDate = false;
    },
    async getCardInfo(id) {
      this.loading = true;
      try {
        const cardInfo = await getUserCardInfo(id);
        this.cardInfo = {
          ...cardInfo,
          uid: id,
        };
        this.loading = false;
        if (this.upDate) return;
        this.list[this.currPop].showLastPop = true;
        await this.setLiveUserPopState({ name: 'LiveGuest', uid: this.list[this.currPop].uid });
      } catch (error) {
        // console.log(error)
      }
    },
    // 处理名片内点击操作
    handleCommand(val) {
      const { item, command } = val;
      switch (command) {
      case 'ppt':
        this.$emit('set-authorize-ppt', { audienceId: item.uid, command });
        break;
      case 'pptBack':
        this.$emit('set-authorize-ppt', { audienceId: item.uid, command });
        break;
      case 'setAudience':
        this.$emit('set-audience', item.uid);
        break;
      case 'closeMic':
        this.$emit('require-close-mic', item.uid);
        break;
      case 'kickOutRoom':
        this.$emit('set-user-kickout', item);
        break;
      case 'setOneselfAudience':
        this.$emit('set-oneself-audience');
        break;
      case 'follow':
        this.$emit('follow', item.uid);
        break;
      case 'chat':
      case 'bp':
      case 'project':
        this.$emit('handle-send-channel', { command, item });
        break;
      default:
        break;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.guest-group {
  display: flex;
  flex-wrap: wrap;

  .guest-popover {
    margin-left: 16px;
    width: calc((100% - 48px) / 4);

    &:nth-of-type(4n + 1) {
      margin-left: 0;
    }
  }

  .guest {
    display: flex;
    margin-top: 24px;

    img {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      border-color: rgba(255, 255, 255, 0.06);
    }
  }

  .top-info {
    display: flex;
  }

  .guest-name {
    font-family: PingFangSC-Medium;
    font-size: 14px;
    color: #333b44;
    line-height: 20px;
    cursor: pointer;

    &:hover {
      color: #3e74ca;
    }
  }

  .guest-hover-none:hover{
    color: #333b44;
  }

  .guest-role {
    width: 52px;
    height: 20px;
    margin-left: 8px;
    line-height: 20px;
    text-align: center;
    border-radius: 2px;
    font-size: 12px;
    color: #fff;

    &.admin {
      background: #3e74ca;
    }

    &.ppt {
      background: #3ab7a8;
    }
  }

  .guest-identity {
    font-family: PingFangSC-Regular;
    font-size: 12px;
    color: #666b71;
    letter-spacing: 0;
    line-height: 18px;
    margin-top: 2px;
  }
}

.logo-panel-avatar {
  /deep/ &.user-logo-content {
    height: 40px;
  }
}
</style>
<style lang="scss">
.guest-popper {
  &[x-placement^=bottom] {
    margin-top: 4px;
  }

  &[x-placement^=right] {
    margin-left: -140px;
    margin-top: 20px;
  }
}
</style>
